<template lang="pug">
.main-wrapper.faturamento-listar
			header.main-wrapper-header
				.p-grid.p-align-center
					.p-col-12.ta-right
						h1.text-header.text-secondary MedClub / <b>Faturamento</b>
	
			Dialog.dialogApagar(header='Remover pagamento' :modal='true' :visible.sync='dialogApagar')
				.ta-center
					p Confirma remover o pagamento <b>cód. {{ dialogApagar_data.id }}</b>?
					.mt-4.my-2
						ProgressSpinner.waitingRemover(v-if='waitingRemover' strokeWidth='4')
						Button.p-button-danger(v-else label='Remover' icon='jam jam-trash' @click='removerPagamento(dialogApagar_data.id)')
	
			Dialog.dialogApagar(header='Gerar relatório' :modal='true' :visible.sync='dialogImprimir')
				.ta-center
					p Selecione o formato de exportação:
					.mt-4.my-2
						ProgressSpinner.waitingRemover(v-if='waitingRelatorio' strokeWidth='4')
						div(v-else)
							Button.p-button.mr-3(label='CSV' icon='jam jam-document' @click='gerarRelatorio(dialogImprimir_data.id,"csv")')
							Button.p-button.mr-3(label='EXCEL' icon='jam jam-document' @click='gerarRelatorio(dialogImprimir_data.id,"xlsx")')
							Button.p-button(label='PDF' icon='jam jam-printer' @click='gerarRelatorio(dialogImprimir_data.id,"pdf")')
	
			Panel.mb-2(v-show='!waiting' header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
				.p-grid.p-fluid.p-align-end
	
					.p-col-12.p-md-10.p-lg-8.p-xl-5
						label.form-label Empresa:
						Dropdown(v-model='filters.cd_estabelecimento' :options='options.estabelecimentos' :filter='options.estabelecimentos.length > 0'
							dataKey='id' optionLabel='nm_fantasia' optionValue='id' placeholder='TODOS' @change='applyFilters()')
	
					.p-col-12.p-md-2
						label.form-label Competência:
						.p-inputgroup
							Calendar.filter-data( v-model='filters.dt_competencia' dateFormat="mm/yy" :locale="ptbr" :yearRange="`2020:${proximoAno}`" :yearNavigator='true'
								:manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' view='month' placeholder='MM/AAAA')
								Button.p-button-danger(v-if='filters.dt_competencia' icon="jam jam-trash" @click='filters.dt_competencia = null')
					.p-col-12.p-md-2
						label.form-label Situação:
						.p-inputgroup
							MultiSelect(v-model='filters.ie_status_pagamento' :options='options.situacao' dataKey='value' optionLabel='label' optionValue='value' placeholder='TODOS' @change='applyFilters()')
					.p-col-12.p-md-5.p-lg-4.p-xl-3
						label.form-label Intervalo de Datas:
						.p-inputgroup
							Calendar.filter-data(v-model='filters.dt_intervalo_list' dateFormat="dd/mm/yy" :locale="ptbr" :yearRange="`2020:${proximoAno}`" :yearNavigator='true' 
							:monthNavigator='true' :manualInput='false' :touchUI='windowInnerWidth < 576' selectionMode='range' placeholder='DD/MM/AAAA - DD/MM/AAAA')
							Button(v-if='filters.dt_intervalo_list.length && !filters.dt_intervalo_list.includes(null)' icon="jam jam-search" @click='applyFilters()')
							Button.p-button-danger(v-if='filters.dt_intervalo_list.length' icon="jam jam-trash" @click='filters.dt_intervalo_list = []')
	
			.ta-right.mt-4.mb-2(v-show='!waiting')
				Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/faturamento/listar-guias/0/')")
	
			ProgressBar(v-if='waiting' mode="indeterminate")
			div(v-else-if='list.length == 0')
				p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
			div(v-else)
				Panel.datatable(header='Lista de pagamentos')
					Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage")
					div(:style="{overflowX:'scroll'}")
						DataTable(:value="list" class='responsive' :style="{minWidth:'1456px'}"  @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder")
							Column(headerStyle='width: 7%;' bodyStyle='text-align: center;' field='id' header='Cód.' sortable)
							Column(headerStyle='width: 20%;' field='cd_estabelecimento__nm_fantasia' header='Empresa')
								template(#body='props')
									span {{ props.data.ds_pagamento }}<br />
									span <b>{{ props.data.cd_estabelecimento.nm_fantasia }}</b>
									span(v-if='props.data.cd_especialista') <br>{{ props.data.cd_especialista.nm_especialista }}
							Column(headerStyle='width: 13%;' bodyStyle='text-align: center;' field='dt_competencia' header='Competência')
								template(#body='props')
											span {{ props.data.dt_competencia_f }}<br />
							Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_valorbruto' header='Valor do Repasse' sortable)
								template(#body='props')
										span {{ props.data.nr_valorbruto_f }}<br />
							Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_valor_medclub' header='Valor Medclub')
								template(#body='props')
										span {{ props.data.nr_valor_medclub_f }}<br />
							Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' field='cd_especialista_banco' header='Banco')
								template(#body='props')
										span(v-if='props.data.cd_especialista_banco != null && props.data.cd_especialista_banco.length > 0') {{props.data.cd_especialista_banco[0].cd_banco.nm_banco}}
										span(v-else-if='props.data.cd_estabelecimento_banco != null && props.data.cd_estabelecimento_banco.length > 0') {{props.data.cd_estabelecimento_banco[0].cd_banco.nm_banco}}
										span(v-else='props.data.cd_especialista_banco == null || props.data.cd_estabelecimento_banco == null')
										//dados de banco no antigo formato.
										span(v-if='props.data.cd_banco != null') {{props.data.cd_banco.nm_banco}}
							Column(headerStyle='width: 12%;' bodyStyle='text-align: left;' field='nr_agenciabancaria' header='Agência e Conta')
								template(#body='props')
										span(v-if='props.data.cd_especialista_banco != null && props.data.cd_especialista_banco.length > 0')
											span Agência:
												strong {{props.data.cd_especialista_banco[0].nr_agencia_bancaria}}<br/>
											span Conta:
												strong {{props.data.cd_especialista_banco[0].nr_conta}}<br/>
											span Operação:
												strong {{props.data.cd_especialista_banco[0].nr_operacao}}
										span(v-else-if='props.data.cd_estabelecimento_banco != null && props.data.cd_estabelecimento_banco.length > 0')
											span Agência:
												strong {{props.data.cd_estabelecimento_banco[0].nr_agencia_bancaria}}<br/>
											span Conta:
												strong {{props.data.cd_estabelecimento_banco[0].nr_conta}}<br/>
											span Operação:
												strong {{props.data.cd_estabelecimento_banco[0].nr_operacao}}
										span(v-else='props.data.cd_especialista_banco == null || props.data.cd_estabelecimento_banco == null')
										span(v-if='props.data.nr_agenciabancaria != null')
											span Agência:
												strong {{props.data.nr_agenciabancaria}}<br/>
											span Conta:
												strong {{props.data.nr_contabancaria}}
							Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='ds_chave_pix' header='Pix')
								template(#body='props')
										span(v-if='props.data.cd_especialista_banco != null && props.data.cd_especialista_banco.length > 0') {{props.data.cd_especialista_banco[0].ds_chave_pix}}
										span(v-else-if='props.data.cd_estabelecimento_banco != null && props.data.cd_estabelecimento_banco.length > 0') {{props.data.cd_estabelecimento_banco[0].ds_chave_pix}}
										span(v-else='props.data.cd_especialista_banco == null || props.data.cd_estabelecimento_banco == null')
							Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='ie_status_pagamento' header='Situação' sortable)
								template(#body='props')
									span(v-if='props.data.ie_status_pagamento == "A"') Faturado
									span(v-else-if='props.data.ie_status_pagamento == "E"') Enviado
									span(v-else='props.data.ie_status_pagamento == "P"') 
										b Pago
										br
										em {{ moment(props.data.dt_pagamento, 'YYYY-MM-DD').format('DD/MM/YYYY') }}
							Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' header='Ações')
								template(#body='props')
									.ta-center
										Button.ml-1.p-button-raised.p-button-rounded(
											v-show='props.data.ie_status_pagamento == "A"'
											v-tooltip.top="'Faturar'"
											icon="jam jam-coin"
											@click="$router.push(`/faturamento/faturar/${ props.data.id }/`)"
											)
										Button.ml-1.p-button-raised.p-button-rounded(
											v-if='props.data.ie_status_pagamento != "P"'
											v-tooltip.top="'Editar'"
											icon="jam jam-write"
											@click="$router.push(`/faturamento/listar-guias/${ props.data.id }/`)"
											)
										Button.ml-1.p-button-raised.p-button-rounded(
											v-else
											v-tooltip.top="'Ver dados'"
											icon="jam jam-search"
											@click="$router.push(`/faturamento/listar-guias/${ props.data.id }/`)"
											)
										span.ml-1
											Button.p-button-raised.p-button-rounded(
												v-tooltip.top="'Gerar relatório'"
												icon="jam jam-printer"
												@click='dialogImprimir_data = props.data; dialogImprimir = true'
											)
										Button.ml-1.p-button-raised.p-button-rounded.p-button-danger(
											v-tooltip.top="'Remover'"
											icon="jam jam-trash"
											@click='dialogApagar_data = props.data; dialogApagar = true'
											)
					Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>
		
<style lang="scss">
	.faturamento-listar {
		.waitingRelatorio, .waitingRemover {
			width: 28px;
			height: auto;
		}
		tr {
			th, td {
				word-wrap: break-word;
			}
		}
	}
</style>
		
<script>
	import ProgressBar from 'primevue/progressbar'
	import ProgressSpinner from 'primevue/progressspinner'
	import Panel from 'primevue/panel'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Paginator from 'primevue/paginator'
	import Button from 'primevue/button'
	import Tooltip from 'primevue/tooltip'
	import Dropdown from 'primevue/dropdown'
	import Calendar from 'primevue/calendar'
	import Dialog from 'primevue/dialog'
	import MultiSelect from 'primevue/multiselect'

	import { Pagamentos, Relatorio, Estabelecimento } from './../../middleware'
	import moment from 'moment'
	import { pCalendarLocale_ptbr } from './../../utils'
	import wsConfigs from './../../middleware/configs'
	import { saveAs } from 'file-saver'
	import _ from 'lodash'
	export default {
		beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'faturamento') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
		created () {
			this.waiting = true
			Estabelecimento.findAllClean().then(response => {
				if (response.status == 200) this.options.estabelecimentos = response.data
				this.applyFilters()
			})
		},
		components: { ProgressBar, Panel, DataTable, Column, Paginator, Button, Tooltip,
			ProgressSpinner, Dropdown, Calendar, Dialog, MultiSelect },
		directives: { tooltip: Tooltip },
		data () {
			return {
				list: [],
				ptbr: pCalendarLocale_ptbr,
				windowInnerWidth: window.innerWidth,
				waiting: false,
				waitingRelatorio: 0,
				waitingRemover: false,
				dialogApagar_data: {},
				dialogApagar: false,
				dialogImprimir: false,
				dialogImprimir_data: {},
				proximoAno: parseInt(moment().format("YYYY"))+1,
				filters: {
					dt_intervalo_list: [],
					ie_status_pagamento: null,
					cd_estabelecimento: null
				},
				options: {
					estabelecimentos: [],
					situacao: [
						{
							value: 'A',
							label: 'Faturado'
						},
						{
							value: 'E',
							label: 'Enviado'
						},
						{
							value: 'P',
							label: 'Pago'
						}
					]
				},
				paginator: {
					page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				order: {
					field: 'dt_pagamento',
					sortOrder: 1
				}
			}
		},
		methods: {
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			getList (params) {
				this.waiting = true
				return Pagamentos.findAll(params).then(response => {
					if (response.status == 200) {
						this.paginator.count = response.data.count
						response.data.results.forEach(pagamento => {
							pagamento.nr_valorbruto_f = this.formatPrice(pagamento.nr_valorbruto)
							pagamento.nr_valor_medclub_f = this.formatPrice(pagamento.nr_valor_medclub)
							if (pagamento.dt_competencia) pagamento.dt_competencia_f = moment(pagamento.dt_competencia,'MM/YYYY').format('MM/YYYY')
						})
						this.list = response.data.results
					}
					this.waiting = false
					return true
				})
			},
			components: { ProgressBar, Panel, DataTable, Column, Paginator, Button, Tooltip,
				ProgressSpinner, Dropdown, Calendar, Dialog, MultiSelect },
			directives: { tooltip: Tooltip },
			data () {
				return {
					list: [],
					ptbr: pCalendarLocale_ptbr,
					windowInnerWidth: window.innerWidth,
					waiting: false,
					waitingRelatorio: 0,
					waitingRemover: false,
					dialogApagar_data: {},
					dialogApagar: false,
					dialogImprimir: false,
					dialogImprimir_data: {},
					proximoAno: parseInt(moment().format("YYYY"))+1,
					filters: {
						dt_intervalo_list: [],
						ie_status_pagamento: null,
						cd_estabelecimento: null
					},
					options: {
						estabelecimentos: [],
						situacao: [
							{
								value: 'A',
								label: 'Faturado'
							},
							{
								value: 'E',
								label: 'Enviado'
							},
							{
								value: 'P',
								label: 'Pago'
							}
						]
					},
					paginator: {
						page: this.$route.query.pg ? this.$route.query.pg : 1,
						per_page: wsConfigs.paginator_perPage,
						count: 0
					},
					order: {
						field: 'dt_pagamento',
						sortOrder: 1
					}
				}
			},
			methods: {
				formatPrice (val) {
					return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
				},
				getList (params) {
					this.waiting = true
					return Pagamentos.findAll(params).then(response => {
						if (response.status == 200) {
							this.paginator.count = response.data.count
							response.data.results.forEach(pagamento => {
								pagamento.nr_valorbruto_f = this.formatPrice(pagamento.nr_valorbruto)
								pagamento.nr_valor_medclub_f = this.formatPrice(pagamento.nr_valor_medclub)
								if (pagamento.dt_competencia) pagamento.dt_competencia_f = moment(pagamento.dt_competencia,'MM/YYYY').format('MM/YYYY')
							})
							this.list = response.data.results
						}
						this.waiting = false
						return true
					})
				},
				applyFilters (page) {
					if (page) {
						this.paginator.page = page
					} else {
						this.paginator.page = this.paginator.page || 1
					}
					let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page };
					params.order = `${this.order.sortOrder === 1 ? '' : '-'}${this.order.field}`;

					Object.keys(this.filters).forEach((key) => {
						if (key === 'dt_competencia') {
							params[key] = moment(this.filters[key]).format('MM/YYYY');
						} else if (key.startsWith('dt_')) {
							if (_.isArray(this.filters[key]) && !_.isEmpty(this.filters[key])) {
								params[key] = this.filters[key].map(fk => moment(fk, 'DD/MM/YYYY').isValid() ? moment(fk, 'DD/MM/YYYY').format('YYYY-MM-DD') : null).filter(Boolean);
							} else if (moment(this.filters[key], 'DD/MM/YYYY').isValid()) {
								params[key] = moment(this.filters[key], 'DD/MM/YYYY').format('YYYY-MM-DD');
							}
						} else if (this.filters[key]) {
							params[key] = this.filters[key];
						}
					});
					this.getList(params)
				},
				onPage (ev) {
					this.applyFilters(ev.page + 1)
				},
				onSort(ev) {
					this.order.field = ev.sortField
					this.order.sortOrder = ev.sortOrder
					this.applyFilters()
				},
				gerarRelatorio (id, formato) {
					this.waitingRelatorio = true
					Relatorio.getRelatorioPagamento(id, formato).then(response => {
						this.waitingRelatorio = 0
						if (response.status == 200) {
							if (response.data.detail) {
								this.$toast.error(response.data.detail, { duration: 3000 })
							} else {
								let blob
								if (formato == 'csv'){
									blob = new Blob([response.data], { type: 'text/csv; charset=utf-8' })
									saveAs(blob, `relatorio-pagamento-guia-${ id }.csv`)
								} else if (formato === 'xlsx') {
									blob = response.data
									saveAs(blob, `relatorio-protocolo-guia-${ id }.xlsx`)
								} else {
									blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
									saveAs(blob, `relatorio-pagamento-guia-${ id }.pdf`)
								}
							}
						}
					})
				},
				removerPagamento (id) {
					this.waitingRemover = true
					Pagamentos.remove(id).then(response => {
						this.waitingRemover = false
						if (([200, 201, 204]).includes(response.status)) {
							this.$toast.info('Pagamento removido com sucesso', { duration: 3000 })
							this.dialogApagar = false
							this.applyFilters()
						} else if (response.data.detail) {
							if (typeof response.data.detail == 'string')
								this.$toast.error(response.data.detail, { duration: 3000 })
							else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
						}
					})
				},
			},
			moment,
		}
	}
</script>
		